/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({ description, meta, title, type, image, slug }) {
  const metaDescription = description;
  const mergedTitle = `${title} | Verger Joannette Bio`;
  const lang = process.env.GATSBY_LANG || "fr";

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={mergedTitle}
      meta={[
        {
          name: `charset`,
          content: `utf-8`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://vergerjoannette.ca/${lang}/${slug || ""}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description ? description : metaDescription,
        },
        {
          property: `og:type`,
          content: type ? type : `website`,
        },
        {
          property: `og:image`,
          content: image || null,
        }
      ].concat(meta)}
    >
      <link rel="canonical" href="https://vergerjoannette.ca/fr/" />
      <link rel="alternate" hreflang="en" href="https://vergerjoannette.ca/en/" />

      <link href="https://fonts.googleapis.com/css?family=Permanent+Marker|Montserrat:400,600|Open+Sans:400,700&display=swap" rel="stylesheet" />

    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: "Verger biologique. Pommes biologiques certifiées par Québec Vrai. 15 variétés. Auto-cueillette, pique-nique et balade en tracteur.",
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
